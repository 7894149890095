<template>
  <div>
    <router-view></router-view>
  </div>

<!--  <div style="margin: 0 auto;width: 454px;height: 40px;margin-bottom: 40px">-->
<!--    <div class="botton_Inf">Copyright Dejoy SD Space Design SD设计公司 版权所有 | 沪ICP备17010633号</div>-->
<!--    <div class="botton_Inf">Contact us 联系方式：88888888</div>-->
<!--  </div>-->

<!--  一级导航栏-->

</template>

<style>

html,body{ height: 100%; margin: 0; padding: 0; font-size: 14px;}
body{
  font:normal100%Helvetica,Arial,sans-serif;
}
body{
  margin:0;
  padding:0;
  scroll:none;
}
body {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
body::-webkit-scrollbar{
  display:none
}
/*@media screen and (max-width:900px ) {*/
/*  #carousel span{font-size: 1em}*/
/*}*/
/*@media screen and (min-width:900px) and (max-width:1400px ) {*/
/*  #carousel span{font-size: 1em}*/
/*}*/
/*@media screen and (min-width:1400px ) {*/
/*  !*html{width: 100%;}*!*/
/*  #carousel span{font-size: 2.3em}*/
/*}*/
.bar_font{
  margin: 0 auto;
  width: 310px;
  height: 48px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #424242;
}
.botton_Inf{
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #424242;
}
/*.header_Nav_show{*/
/*  box-sizing: border-box;*/
/*  padding-top: 16px;*/
/*  width: 100%;*/
/*  height: 142px;*/
/*  z-index: 100;*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*}*/

/*.white{*/
/*  width: 0%;*/
/*  height: 45px;*/
/*  background-color: white;*/
/*  position: absolute;*/
/*  top:calc(50% + 20px);*/
/*  transform: translate(0,-50%);*/
/*  transition: all 0.5s linear;*/
/*}*/
/*.first_nav1{*/
/*  width: 25%;*/
/*  height: 100vh;*/
/*  border-right: 1px solid white;*/
/*  background: #EDEDED;*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  !*transition: all 1s;*!*/
/*  animation: navShow 1s infinite;*/
/*  animation-iteration-count:1*/
/*}*/
/*@keyframes navShow {*/
/*  0%{*/
/*    transform: scaleX(0);*/
/*    transform-origin:0 0;*/
/*  }*/
/*  100%{*/
/*    transform: scaleX(1);*/
/*    transform-origin:0 0;*/
/*  }*/
/*}*/
/*@keyframes navUnshow {*/
/*  0%{*/
/*    transform: scaleX(1);*/
/*    transform-origin:0 0;*/
/*  }*/
/*  100%{*/
/*    transform: scaleX(0);*/
/*    transform-origin:0 0;*/
/*  }*/
/*}*/
/*.first_nav2{*/
/*  width: 25%;*/
/*  height: 100vh;*/
/*  border-right: 1px solid white;*/
/*  background: #EDEDED;*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  animation: navShow 1s infinite;*/
/*  animation-iteration-count:1;*/
/*  animation-delay:0.2s;*/
/*}*/
/*.first_nav3{*/
/*   width: 25%;*/
/*   height: 100vh;*/
/*   border-right: 1px solid white;*/
/*   background: #EDEDED;*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  animation: navShow 1s infinite;*/
/*  animation-iteration-count:1;*/
/*  animation-delay:0.4s;*/
/*}*/
/*.first_nav1::after{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  content: "WORK";*/
/*  width: 95px;*/
/*  height: 39px;*/
/*  font-family: 'Inter';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 32px;*/
/*  line-height: 39px;*/
/*  !* identical to box height *!*/
/*  !*text-decoration-line: underline;*!*/
/*  color: #424242;*/
/*}*/
/*.first_nav2::after{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  content: "ABOUT";*/
/*  width: 95px;*/
/*  height: 39px;*/
/*  font-family: 'Inter';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 32px;*/
/*  line-height: 39px;*/
/*  !* identical to box height *!*/
/*  !*text-decoration-line: underline;*!*/
/*  color: #424242;*/
/*}*/
/*.first_nav3::after{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  content: "JOBS";*/
/*  width: 95px;*/
/*  height: 39px;*/
/*  font-family: 'Inter';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 32px;*/
/*  line-height: 39px;*/
/*  !* identical to box height *!*/
/*  !*text-decoration-line: underline;*!*/
/*  color: #424242;*/
/*}*/
/*.first_nav4::after{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  content: "CONTACT";*/
/*  width: 95px;*/
/*  height: 39px;*/
/*  font-family: 'Inter';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 32px;*/
/*  line-height: 39px;*/
/*  !* identical to box height *!*/
/*  !*text-decoration-line: underline;*!*/
/*  color: #424242;*/
/*}*/
/*.first_nav4{*/
/*    width: 25%;*/
/*    height: 100vh;*/
/*    border-right: 1px solid white;*/
/*    background: #EDEDED;*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  animation: navShow 1s infinite;*/
/*  animation-delay:0.6s;*/
/*  animation-iteration-count:1;*/
/*  }*/

/*.first_nav4:hover::after{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  content: "CONTACT";*/
/*  width: 95px;*/
/*  height: 39px;*/
/*  font-family: 'Inter';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 32px;*/
/*  line-height: 39px;*/
/*  !* identical to box height *!*/
/*  text-decoration-line: underline;*/
/*  color: #424242;*/
/*}*/
/*.first_nav3:hover::after{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  content: "JOBS";*/
/*  width: 95px;*/
/*  height: 39px;*/
/*  font-family: 'Inter';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 32px;*/
/*  line-height: 39px;*/
/*  !* identical to box height *!*/
/*  text-decoration-line: underline;*/
/*  color: #424242;*/
/*}*/
/*.first_nav2:hover::after{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  content: "ABOUT";*/
/*  width: 95px;*/
/*  height: 39px;*/
/*  font-family: 'Inter';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 32px;*/
/*  line-height: 39px;*/
/*  !* identical to box height *!*/
/*  text-decoration-line: underline;*/
/*  color: #424242;*/
/*}*/
/*.first_nav1:hover::after{*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  content: "WORK";*/
/*  width: 95px;*/
/*  height: 39px;*/
/*  font-family: 'Inter';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-size: 32px;*/
/*  line-height: 39px;*/
/*  !* identical to box height *!*/
/*  text-decoration-line: underline;*/
/*  color: #424242;*/
/*}*/
/*.first_nav1:hover{*/
/*  background: url("../sd_IMG/9.png") #EDEDED;*/
/*  opacity: 0.8;*/
/*}*/
/*.first_nav1:hover .white{*/
/*  width: 80%;*/
/*}*/
/*.first_nav2:hover{*/
/*  background: url("../sd_IMG/10.png") #EDEDED;*/
/*  opacity: 0.8;*/
/*}*/
/*.first_nav2:hover .white{*/
/*  width: 80%;*/
/*}*/
/*.first_nav3:hover{*/
/*  background: url("../sd_IMG/11.png") #EDEDED;*/
/*  opacity: 0.8;*/
/*}*/
/*.first_nav3:hover .white{*/
/*  width: 80%;*/
/*}*/
/*.first_nav4:hover{*/
/*  background: url("../sd_IMG/12.png") #EDEDED;*/
/*  opacity: 0.8;*/
/*}*/
/*.first_nav4:hover .white{*/
/*  width: 80%;*/
/*}*/
/*@media screen and (max-width:320px ) {*/
/*  !*#carousel span {font-size: 1em}*!*/
/*  html{width: 1200px;}*/
/*}*/
/*@media screen and (min-width:321px) and (max-width:1400px ) {*/
/*  !*#carousel span {font-size: 1em}*!*/
/*  html{width: 1200px;}*/
/*}*/
/*@media screen and (min-width:1400px ) {*/
/*  html{width: 100%;}*/
/*  !*#carousel span {font-size: 2em}*!*/
/*}*/
</style>
