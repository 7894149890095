import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import createStore from './store'

const app=createApp((App))
app.use(router)
// app.config.globalProperties.$showNav=false
app.provide('$showNav', false)
app.mount('#app')

