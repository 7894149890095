import { createRouter,createWebHistory } from "vue-router"

const routes = [
    {
        path:'/',
        redirect:'/index',
    },
    {
        path:'/index',
        name:'index',
        component:()=>import('@/pages/homeIndex')
    },
     {
        path:'/WORK', //地产板块
        name:'WORK',
        component:()=>import('@/pages/civicPages/civicHome'),
     },
     {
        path:'/residential',
        name:'residential',
        component:()=>import('@/pages/residentialPages/residentialHome')
        },
     {
        path:'/惠州德丰玖御府大平层样板房',
        name:'惠州德丰玖御府大平层样板房',
        component:()=>import('@/pages/residentialPages/huizhouDFDapingceng')
     },
     {
        path:'/南头古城南街公共卫生间',
        name:'南头古城南街公共卫生间',
        component:()=>import('@/pages/residentialPages/NtSouthPublicToilet')
     },
     {
        path:'/南头古城东街卫生间',
        name:'南头古城东街卫生间',
        component:()=>import('@/pages/residentialPages/NtToilet')
     },
     {
        path:'/南头古城泊寓撸猫公区',
        name:'南头古城泊寓撸猫公区',
        component:()=>import('@/pages/residentialPages/vankeCat')
     },
     {
        path:'/南头古城泊寓旗舰店泊阅汇',
        name:'南头古城泊寓旗舰店泊阅汇',
        component:()=>import('@/pages/residentialPages/vankePoyuQi')
     },
     {
        path:'/威海新星宇北纬37度阳光屿售楼处',
        name:'威海新星宇北纬37度阳光屿售楼处',
        component:()=>import('@/pages/residentialPages/WH37')
     },
     {
           path:'/万科南宁瑧山悦180样板房',
           name:'万科南宁瑧山悦180样板房',
           component:()=>import('@/pages/residentialPages/vake180'),
           },
     {
           path:'/惠州德丰玖御府别墅样板房',
           name:'惠州德丰玖御府别墅样板房',
           component:()=>import('@/pages/residentialPages/DFbieshu'),
           },
     {
            path:'/惠州德丰玖御府售楼处',
            name:'惠州德丰玖御府售楼处',
            component:()=>import('@/pages/residentialPages/huizhouDF'),
            },
     {
             path:'/惠州隆腾盛世样板房239',
             name:'惠州隆腾盛世样板房239',
             component:()=>import('@/pages/residentialPages/huizhouLS239'),
             },
             {
                path:'/惠州隆腾盛世样板房93',
                name:'惠州隆腾盛世样板房93',
                component:()=>import('@/pages/residentialPages/LS93')
             },
             {
                path:'/惠州隆腾盛世样板房1021',
                name:'惠州隆腾盛世样板房1021',
                component:()=>import('@/pages/residentialPages/LS102_1')
             },
             {
                path:'/惠州隆腾盛世样板房1022',
                name:'惠州隆腾盛世样板房1022',
                component:()=>import('@/pages/residentialPages/LS102_2')
             },
     {
             path:'/漳州万科金域滨江样板房',
             name:'漳州万科金域滨江样板房',
             component:()=>import('@/pages/residentialPages/ZzVake'),
             },
     {
             path:'/隆腾盛世12号楼样板房',
             name:'隆腾盛世12号楼样板房',
             component:()=>import('@/pages/residentialPages/LS12'),
             },
     {
             path:'/隆腾盛世97㎡户型',
             name:'隆腾盛世97㎡户型',
             component:()=>import('@/pages/residentialPages/LS97'),
             },
     {
             path:'/惠州隆腾盛世样板房255户型',
             name:'惠州隆腾盛世样板房255户型',
             component:()=>import('@/pages/residentialPages/LS255'),
             },
     {
             path:'/南头古城泊寓展厅及户型样板间',
             name:'南头古城泊寓展厅及户型样板间',
             component:()=>import('@/pages/residentialPages/NTzhan'),
             },
      {
             path:'/commerical',
             name:'commerical',
             component:()=>import('@/pages/commericalPages/commericalHome'),
          },
          {
            path:'/万科名车mall',
            name:'万科名车mall',
            component:()=>import('@/pages/commericalPages/XMmail')
          },
          {
            path:'/珠海万科海上城市一期',
            name:'珠海万科海上城市一期',
            component:()=>import('@/pages/commericalPages/ZHVankeHaiS')
          },
          {
            path:'/福州金山万科里',
            name:'福州金山万科里',
            component:()=>import('@/pages/commericalPages/FZJSVanke')
          },
          {
            path:'/深圳新安街道商业街改造',
            name:'深圳新安街道商业街改造',
            component:()=>import('@/pages/commericalPages/SZXinan')
          },
          {
            path:'/深圳福田中洲滨海幼儿园',
            name:'深圳福田中洲滨海幼儿园',
            component:()=>import('@/pages/commericalPages/FTZhongzhou')
          },
          {
            path:'/深圳合水口古村建筑',
            name:'深圳合水口古村建筑',
            component:()=>import('@/pages/commericalPages/SZHeshuikou')
          },
          {
             path:'/深圳龙华高级中学',
             name:'深圳龙华高级中学',
             component:()=>import('@/pages/commericalPages/SZLonghuaMiddle')
           },
           {
             path:'/横岗文体广场',
             name:'横岗文体广场',
             component:()=>import('@/pages/commericalPages/SZHuarunHG1')
           },
           {
             path:'/广西南宁长虹路万科里商业中心',
             name:'广西南宁长虹路万科里商业中心',
             component:()=>import('@/pages/commericalPages/GXVanke')
           },
           {
            path:'/大疆百旺信六区七区立面及景观改造项目',
            name:'大疆百旺信六区七区立面及景观改造项目',
            component:()=>import('@/pages/commericalPages/DJ6')
           },
           {
            path:'/0厦门云城万科里商业街',
            name:'0厦门云城万科里商业街',
            component:()=>import('@/pages/commericalPages/XMVanke')
           },
       {
              path:'/civic',
              name:'civic',
              component:()=>import('@/pages/civicPages/civicHome'),

           },
           {
            path:'/深圳华润园山文体中心景观',
            name:'深圳华润园山文体中心景观',
            component:()=>import('@/pages/civicPages/huayuanJing')
           },
       {
             path:'/青少年活动中心',
             name:'青少年活动中心',
             component:()=>import('@/pages/civicPages/youthCenter')

       },
      {
             path:'/深圳华润园山文体中心',
             name:'深圳华润园山文体中心',
             component:()=>import('@/pages/civicPages/Huaruncenter')

      },
      {
            path:'/深圳万科滨海云中心EPC办公室',
            name:'深圳万科滨海云中心EPC办公室',
            component:()=>import('@/pages/civicPages/vankeEPC')
      },
      {
            path:'/深圳华润横岗文体广场',
            name:'深圳华润横岗文体广场',
            component:()=>import('@/pages/civicPages/huarunHG')
            },
      {
            path:'/南山福利中心',
            name:'南山福利中心',
            component:()=>import('@/pages/civicPages/nanshanFL')
            },
     {
            path:'/南头古城数字博物馆',
            name:'南头古城数字博物馆',
            component:()=>import('@/pages/civicPages/NTMuseum')
            },
     {
            path:'/深圳南山中医院',
            name:'深圳南山中医院',
            component:()=>import('@/pages/civicPages/NSHospital')
            },
     {
           path:'/厦门万科鼎丰万科里',
           name:'厦门万科鼎丰万科里',
           component:()=>import('@/pages/civicPages/vanke1125')
           },
     {
          path:'/万科罗湖图书馆',
          name:'万科罗湖图书馆',
          component:()=>import('@/pages/civicPages/vankeLibrary')
          },
        {
            path:'/education',
            name:'education',
            component:()=>import('@/pages/educationPages/educationHome')
            },
        {
            path:'/深圳福田中学',
            name:'深圳福田中学',
            component:()=>import('@/pages/educationPages/FTMiddle')
        },
        {
            path:'/惠州三和幼儿园',
            name:'惠州三和幼儿园',
            component:()=>import('@/pages/educationPages/HZsanhe')
        },
       {
             path:'/龙湖伦教幼儿园',
             name:'龙湖伦教幼儿园',
             component:()=>import('@/pages/educationPages/LunjiaoKin')
               },
       {
             path:'/深圳梅沙书院',
             name:'深圳梅沙书院',
             component:()=>import('@/pages/educationPages/meiSha')
               },
       {
             path:'/广州万溪花地湾省实学校',
             name:'广州万溪花地湾省实学校',
             component:()=>import('@/pages/educationPages/GZhuadiwan')
               },
       {
             path:'/深圳中洲滨海实验幼儿园',
             name:'深圳中洲滨海实验幼儿园',
             component:()=>import('@/pages/educationPages/zhongzhouBinhai')
               },
       {
             path:'/惠州万科金利达幼儿园',
             name:'惠州万科金利达幼儿园',
             component:()=>import('@/pages/educationPages/HZVankeJ')
               },
       {
             path:'/江西抚州恒大足球小镇中小学',
             name:'江西抚州恒大足球小镇中小学',
             component:()=>import('@/pages/educationPages/JXhengda')
               },
       {
             path:'/深圳坪山江边学校',
             name:'深圳坪山江边学校',
             component:()=>import('@/pages/educationPages/PZJiangbian')
               },
       {
             path:'/东莞长安靖海学校',
             name:'东莞长安靖海学校',
             component:()=>import('@/pages/educationPages/DGchangan')
               },
       {
            path:'/坂田南学校景观项目',
            name:'坂田南学校景观项目',
            component:()=>import('@/pages/educationPages/BTsouth')
       },
       {
           path:'/广州金茂都会四季华附小学',
           name:'广州金茂都会四季华附小学',
           component:()=>import('@/pages/educationPages/GZjingmao')
       },
       {
            path:'/广州万科沙步复配二小学',
            name:'广州万科沙步复配二小学',
            component:()=>import('@/pages/educationPages/GZShabu2')
       },
       {
            path:'/广州万科沙步复配四小学',
            name:'广州万科沙步复配四小学',
            component:()=>import('@/pages/educationPages/GZShabu4')
       },
       {
            path:'/广州万科文冲城市之光华附小学',
            name:'广州万科文冲城市之光华附小学',
            component:()=>import('@/pages/educationPages/GZVankeWenchong')
       },
       {
            path:'/惠州万科双月湾幼儿园',
            name:'惠州万科双月湾幼儿园',
            component:()=>import('@/pages/educationPages/HZSshuangyuewan')
       },
       {
            path:'/珠海湖心金茂悦幼儿园',
            name:'珠海湖心金茂悦幼儿园',
            component:()=>import('@/pages/educationPages/ZHhuxinK')
       },
       {
            path:'/1中山万科梅沙学校',
            name:'1中山万科梅沙学校',
            component:()=>import('@/pages/educationPages/ZsMeisha')
       },
     {
        path:'/ABOUT',
        name:'ABOUT',
        component:()=>import('@/pages/ABOUT'),
        children:[]
     },
     {
        path:'/JOBS',
        name:'JOBS',
        component:()=>import('@/pages/JOBS'),
     },
     {
        path:'/CONTACT',
        name:'CONTACT',
        component:()=>import('@/pages/CONTACT'),
     },
     {
      path:'/TEAM',
      name:'TEAM',
      component:()=>import('@/pages/TEAM')
      },
      {
      path:'/PUBLICATION',
      name:'PUBLICATION',
      component:()=>import('@/pages/PUBLICATION')
      },
      {
        path:'/AWARDS',
        name:'AWARDS',
        component:()=>import('@/pages/AWARDS')
      },
      {
        path:'/NEWS',
        name:'NEWS',
        component:()=>import('@/pages/NEWS')
      },
      {
        path:'/CLIENT',
        name:'CLIENT',
        component:()=>import('@/pages/CLIENT')
      },
      {
        path:'/workDetail',
        name:'workDetail',
        component:()=>import('@/pages/workDetail')
      },
//        {
//             Path:'/广州北万小学',
//             name:'广州北万小学',
//             component:()=>import('@/pages/educationPages/GZBeiwan')
//        },
]

export const router = createRouter({
    history: createWebHistory(),
    // history:createWebHashHistory(),
    routes:routes
})

export default router
